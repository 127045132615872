import domReady from '@roots/sage/client/dom-ready';
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import {MDCTextField} from '@material/textfield';

Alpine.plugin(persist)

const textFields = [].map.call(document.querySelectorAll('.mdc-text-field'), function(el) {
	return new MDCTextField(el);
});

/**
 * Application entrypoint
 */
domReady(async () => {
  window.Alpine = Alpine
  Alpine.start()
});

/**
 * Alpine.js store
 */
document.addEventListener('alpine:init', () => {
  Alpine.store('darkMode', {
    on: Alpine.$persist(false).as('darkMode_on'),
    toggle() {
      this.on = ! this.on

      this.on ? document.documentElement.classList.add('dark') : document.documentElement.classList.remove('dark')

      localStorage.theme = this.on ? 'dark' : 'light'
    }
  })
})

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
